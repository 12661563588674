<template>
  <div id="app">
    <div class="container">
      <!--UPLOAD-->
      <form enctype="multipart/form-data" method="post" novalidate v-if="isInitial || isSaving">
        <h1>Upload your bee sounds</h1>
        <div class="dropbox">
          <input type="file" multiple :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target)"
            accept="audio/*" class="input-file">
            <p v-if="isInitial">
              Drag your file(s) here to begin<br> or click to browse
            </p>
            <p v-if="isSaving">
              Uploading files...
            </p>
        </div>
        <ul>
          <li class="file" v-for="(file, idx) in uploadFiles" :key=idx>
            {{ file.name }}<br>
            <audio :src=file.url controls="controls"></audio><br>
            <!-- label>Swarming: <input type="checkbox" name="swarming[idx]"></label -->
          </li>
        </ul>
        <button @click.prevent="save">Upload</button>
      </form>
      <!--SUCCESS-->
      <div v-if="isSuccess">
        <h2>Thank you for sharing your files.</h2>
      </div>
      <!--FAILED-->
      <div v-if="isFailed">
        <h2>Upload failed.</h2>
      </div>
    </div>
  </div>
</template>

<script>
  import { upload } from './file-upload.service';   // real service

  const STATUS_INITIAL = 0,
        STATUS_SAVING = 1,
        STATUS_SUCCESS = 2,
        STATUS_FAILED = 3;

  export default {
    name: 'app',
    data() {
      return {
        uploadFiles: [],
        uploadedFiles: [],
        uploadError: null,
        currentStatus: null,
        uploadFieldName: 'photos'
      }
    },
    computed: {
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      }
    },
    methods: {
      reset() {
        // reset form to initial state
        this.currentStatus = STATUS_INITIAL;
        this.uploadedFiles = [];
        this.uploadError = null;
      },
      save() {
        // upload data to the server
        this.currentStatus = STATUS_SAVING;
        const formData = new FormData();

        this.uploadFiles.forEach(({ file, name }) => {
          formData.append('upload', file);
        });

        upload(formData)
          .then(x => {
            this.uploadedFiles = [];
            this.currentStatus = STATUS_SUCCESS;
          })
          .catch(err => {
            this.uploadedFiles = [];
            this.uploadError = err.response;
            this.currentStatus = STATUS_FAILED;
          });
      },
      filesChange(target) {
        const fileList = target.files;
        const fieldName = target.name;

        Array
          .from(Array(fileList.length).keys())
          .map(x => {
            const file = fileList[x]
            const url = URL.createObjectURL(file);
            const name = file.name;
            this.uploadFiles.push({ file, name, url });
          });
      }
    },
    mounted() {
      this.reset();
    },
  }

</script>

<style>
  .dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -20px;
    background: lightcyan;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }
  
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }
  
  .dropbox:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
  }
  
  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }

  .file {
    padding: .5em;
  }
</style>
